import React, { useState, useEffect } from "react";
import axios from "axios";
import { IconLoading } from "../../components/IconLoading";
import { URL_LIST_USERS } from "../../config";
import { UserList } from "./UserList";
import { useUserData } from "../../context/useUserData";
import { ButtonCustom } from "../../components/cta/ButtonCustom";
import { CardTitle } from "../user-profile/Cards";
import { isTokenExpired } from "../../context/useAuth";
import { getSubscriptionByPlanId } from "../pricing/dataPricing";

export const UserListView = () => {
  const { token } = useUserData();
  const [userList, setUserList] = useState([]);
  const [userListFiltered, setUserListFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(3);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSubscriberOnly, setShowSubscriberOnly] = useState(true);

  useEffect(() => {
    fetchUserList();
  }, [page]);

  useEffect(() => {
    handleShowSubscriberOnlyChange(showSubscriberOnly);
  }, [userList]);

  const fetchUserList = async (reset = false) => {
    try {
      setLoading(true);
      
      const authToken = await (isTokenExpired(token)
        ? refreshToken()
        : Promise.resolve(token));

      const response = await axios.get(URL_LIST_USERS, {
        params: { page, pageSize: 300, search: searchTerm },
        headers: {
          Authorization: authToken,
        },
      });

      const refinedData = response.data.users.map((d) => ({
        ...d,
        plan: getSubscriptionByPlanId(d.planId),
      }));

      if (reset) {
        setUserList(refinedData);
      } else {
        setUserList((prevUsers) => [...prevUsers, ...refinedData]);
      }
      setTotalUsers(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    console.log("handleSearch was triggered", { searchTerm });
    setPage(1);
    fetchUserList(true);
  };

  const handleShowSubscriberOnlyChange = (val) => {
    setShowSubscriberOnly(val);

    if (!val) {
      setUserListFiltered(userList);
    } else {
      setUserListFiltered(userList.filter((u) => u.isSubscriber));
    }
  };

  if (loading) {
    return <IconLoading />;
  }

  const LoadMoreButton = () => {
    if (userList.length >= totalUsers) return;

    return (
      <ButtonCustom
        onClick={() => setPage((prevPage) => prevPage + 1)}
        text="Load more"
        style="mt-4"
        isHighlight={true}
      />
    );
  };

  return (
    <div className="">
      <CardTitle prefix={""} name={"User List"} />

      <div className="flex items-center pt-4">
        <input
          type="text"
          placeholder="Search users"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="text-black p-2 text-left rounded-md w-full md:w-1/3"
          onKeyDown={(e) => {
            e.key === "Enter" && handleSearch();
          }}
          onBlur={handleSearch}
        />
      </div>

      <div className="mt-4">
        <label className="text-lg">
          <input
            type="checkbox"
            checked={showSubscriberOnly}
            onChange={(e) => handleShowSubscriberOnlyChange(e.target.checked)}
            className="mr-2 w-6 h-6 checkbox"
          />{" "}
          Show only active subscribers
        </label>
      </div>

      <p className="py-2 pt-8">
        Loaded {userList.length} of {totalUsers} users
      </p>

      <UserList userList={userListFiltered} />
      <LoadMoreButton />
    </div>
  );
};
